<template>
  <div class="clebex-widget-item-wrapper plan">
    <div v-if="data && data.length" class="clebex-widget-item">
      <div
        v-for="(plan, i) in data"
        :key="i"
        class="clebex-widget-plan-wrapper"
        @click="declarePlan(plan)"
      >
        <div class="clebex-widget-plan-text">
          <div class="clebex-widget-plan-top">
            <span v-if="plan.resource"> {{ plan.resource.name }}</span>
            <span v-else-if="plan.resource_type_id">
              {{
                plan.resource_type_id.translations.find(
                  el => el.locale === locale
                )
                  ? plan.resource_type_id.translations.find(
                      el => el.locale === locale
                    ).attributes.name
                  : plan.resource_type_id.name
              }}</span
            >
            <span v-else-if="plan.resource_level_id">
              {{
                plan.resource_level_id.translations.find(
                  el => el.locale === locale
                )
                  ? plan.resource_level_id.translations.find(
                      el => el.locale === locale
                    ).attributes.name
                  : plan.resource_level_id.name
              }}</span
            >
            <span>{{ plan.level && plan.level.length ? ", " : "" }}</span>
            <span
              class="clebex-widget-plan-top-parents"
              v-if="plan.level && plan.level.length"
              >{{ plan.level.join(", ") }}</span
            >
          </div>
          <div class="clebex-widget-plan-bottom">
            {{ plan.period.name }} | {{ getTime(plan.datetime_from) }} -
            {{ getTime(plan.datetime_to) }}
          </div>
        </div>
        <div
          v-if="plan.id !== unresolvedDeclaration"
          class="clebex-widget-plan-icon"
          :class="
            plan.resource
              ? displayOccupancyClass(
                  plan.resource.occupancy_percentage,
                  plan.datetime_from
                )
              : 'not-resource'
          "
        ></div>
      </div>
    </div>
    <div
      v-else
      class="clebex-widget-item empty-widget"
      @click="goToPlanSettings"
    >
      <icon class="icon" icon="#cx-app1-add" width="40" height="40" />
      <div class="empty-widget-label">
        {{ displayLabelName("one-click", "widget-plan", "add-plan") }}
      </div>
    </div>
    <screen-modal
      v-if="showModal"
      class="confirm-modal"
      type="success"
      :confirm-button-label="
        displayLabelName('one-click', 'one-click', 'confirm')
      "
      :cancel-button-label="
        displayLabelName('one-click', 'one-click', 'cancel')
      "
      :confirm-action="declarePlanModal"
      :show="showModal"
      @close="showModal = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName("one-click", "one-click", "declaration-confirmed")
        }}
      </h3>
      <template v-if="setPlan">
        <p>
          {{ setPlan.period.name }} | {{ getTime(setPlan.datetime_from) }} -
          {{ getTime(setPlan.datetime_to) }}
        </p>
        <p>
          <span v-if="setPlan.resource"> {{ setPlan.resource.name }}</span>
          <span v-else-if="setPlan.resource_type_id">
            {{
              setPlan.resource_type_id.translations.find(
                el => el.locale === locale
              )
                ? setPlan.resource_type_id.translations.find(
                    el => el.locale === locale
                  ).attributes.name
                : setPlan.resource_type_id.name
            }}</span
          >
          <span v-else-if="setPlan.resource_level_id">
            {{
              setPlan.resource_level_id.translations.find(
                el => el.locale === locale
              )
                ? setPlan.resource_level_id.translations.find(
                    el => el.locale === locale
                  ).attributes.name
                : setPlan.resource_level_id.name
            }}</span
          >{{ setPlan.level && setPlan.level.length ? ", " : "" }}
          <span v-if="setPlan.level && setPlan.level.length">{{
            setPlan.level.join(", ")
          }}</span>
        </p>
      </template>
    </screen-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import httpServiceAuth, { getLang, getUserId } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { mapState, mapActions } from "vuex";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";

export default {
  name: "WidgetsPlan",
  data() {
    return {
      setPlan: null,
      showModal: false,
      channel: null,
      unresolvedDeclaration: null
    };
  },
  mounted() {
    this.channel = subscribe(
      "declaration",
      [getUserId()],
      wsEvents.declaration.changed,
      this.wsCallback
    );
  },
  computed: {
    ...mapState("companyPreferences", ["preferences"]),
    ...mapState("plan", ["selectedLevel"]),
    ...mapState("resource", ["resourceModels"]),
    locale() {
      return getLang();
    }
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...mapActions("oneClick", ["getOneClickWidgets"]),
    ...mapActions("plan", [
      "selectPlanLevel",
      "selectPlanResource",
      "selectPlanFrequentResourceType"
    ]),
    ...mapActions("resource", ["getResourceModels"]),
    getTime(date) {
      return dayjs(date).format("HH:mm");
    },
    displayOccupancyClass(occupancy_percentage, dateTimeFrom) {
      let partiallyBusy = 60;
      let busy = 90;

      let partiallyBusyPreference = this.preferences.find(
        el => el.preference === "PARTIALLY_BUSY_PERCENTAGE"
      );
      let busyPreference = this.preferences.find(
        el => el.preference === "BUSY_PERCENTAGE"
      );
      if (
        partiallyBusyPreference &&
        partiallyBusyPreference.preference_values &&
        partiallyBusyPreference.preference_values.length &&
        busyPreference &&
        busyPreference.preference_values &&
        busyPreference.preference_values.length
      ) {
        partiallyBusy = partiallyBusyPreference.preference_values[0].value;
        busy = busyPreference.preference_values[0].value;
      }

      if (
        occupancy_percentage >= busy ||
        dayjs(dateTimeFrom).isBefore(dayjs())
      ) {
        if (occupancy_percentage < busy) {
          this.getOneClickWidgets(false);
        }
        return "high";
      } else if (
        occupancy_percentage > partiallyBusy &&
        occupancy_percentage < busy
      ) {
        return "moderate";
      } else {
        return "low";
      }
    },
    declarePlan(plan) {
      if (
        plan.resource &&
        this.displayOccupancyClass(
          plan.resource.occupancy_percentage,
          plan.datetime_from
        ) !== "low"
      ) {
        this.$store.commit("oneClick/setSetPlan", plan, { root: true });
        this.selectLevel(plan);
      } else {
        this.setPlan = plan;
        this.showModal = true;
      }
    },
    declarePlanModal() {
      this.unresolvedDeclaration = this.setPlan.id;
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      let dataToSend = null;
      dataToSend = {
        occupancy_percentage: this.setPlan.resource
          ? this.setPlan.resource.occupancy_percentage
          : 0,
        slots: [
          {
            datetime_from: this.setPlan.datetime_from,
            datetime_to: this.setPlan.datetime_to
          }
        ],
        type: this.setPlan.type
      };
      if (this.setPlan.resource) {
        httpServiceAuth
          .post(
            `${apiEndpoints.company.declarations}/resources/${this.setPlan.resource.id}`,
            dataToSend
          )
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
            this.setPlan = null;
          });
      } else if (this.setPlan.resource_type_id) {
        this.getResourceModels().then(() => {
          const params = { mode: "INDIRECT" };
          if (
            this.resourceModels &&
            this.resourceModels.data &&
            this.resourceModels.data.length
          ) {
            params.resource_model_ids = [
              this.resourceModels.data.filter(
                item => item.model === "DECLARE"
              )[0].id
            ];
          }
          return httpServiceAuth
            .get(
              `${apiEndpoints.company.levels}/${this.setPlan.level_id}/resources/all`,
              {
                params: {
                  ...params,
                  resource_type_ids: [this.setPlan.resource_type_id.id]
                }
              }
            )
            .then(response => {
              const { data } = response.data;
              if (data && data.length) {
                dataToSend.resource_ids = data.map(item => item.id);
              }
            })
            .catch(() => {
              this.$store.commit("oneClick/setSetPlan", this.setPlan, {
                root: true
              });
              this.selectLevel(this.setPlan);
            })
            .finally(() => {
              httpServiceAuth
                .post(
                  `${apiEndpoints.company.declarations}/resources`,
                  dataToSend
                )
                .catch(() => {
                  this.$store.commit("oneClick/setSetPlan", this.setPlan, {
                    root: true
                  });
                  this.selectLevel(this.setPlan);
                })
                .finally(() => {
                  this.setPlan = null;
                  this.$store.commit("loader/setScreenLoading", false, {
                    root: true
                  });
                });
            });
        });
      } else if (this.setPlan.resource_level_id) {
        httpServiceAuth
          .post(
            `${apiEndpoints.company.declarations}/levels/${this.setPlan.resource_level_id.id}`,
            dataToSend
          )
          .catch(() => {
            this.$store.commit("oneClick/setSetPlan", this.setPlan, {
              root: true
            });
            this.selectLevel(this.setPlan);
          })
          .finally(() => {
            this.setPlan = null;
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      }
    },
    goToPlanSettings() {
      this.$router.push({
        name: "r_one-click-plan-add",
        params: {
          backLinkName: "r_one-click"
        }
      });
    },
    async getLevel(levelId) {
      return httpServiceAuth.get(`${apiEndpoints.company.levels}/${levelId}`, {
        params: {
          includes: ["all_parents"]
        }
      });
    },
    async getResource(resourceId) {
      return httpServiceAuth.get(
        `${apiEndpoints.company.resources}/${resourceId}`,
        {
          params: {
            includes: ["all_parents"]
          }
        }
      );
    },
    async selectLevel(plan) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.selectPlanResource(null);
      this.selectPlanFrequentResourceType(null);
      if (plan.resource) {
        try {
          const resource = await this.getResource(plan.resource.id);
          if (resource && resource.data && resource.data.data) {
            if (resource.data.data.level_id) {
              this.selectPlanLevel({
                includes: ["default", "directly_declarable", "resources_count"],
                level_id: resource.data.data.level_id
              })
                .then(() => {
                  this.selectPlanResource(resource.data.data);
                  this.$router.push({
                    name: "r_one-click-slots",
                    params: {
                      one_click: true
                    }
                  });
                })
                .finally(() => {
                  this.$store.commit("loader/setScreenLoading", false, {
                    root: true
                  });
                });
            } else {
              this.selectPlanResource(resource.data.data);
              this.$store.commit("loader/setScreenLoading", false, {
                root: true
              });
              this.$router.push({
                name: "r_one-click-slots",
                params: {
                  one_click: true
                }
              });
            }
          }
        } catch (error) {
          if (error && error.response) {
            errorHandler(error.response);
          }
        }
      } else if (plan.resource_type_id) {
        try {
          this.selectPlanLevel({
            includes: ["default", "directly_declarable", "resources_count"],
            level_id: plan.level_id
          });
          this.selectPlanFrequentResourceType(plan.resource_type_id);
          this.$store.commit("loader/setScreenLoading", false, {
            root: true
          });
          this.$router.push({
            name: "r_one-click-slots",
            params: {
              one_click: true
            }
          });
        } catch (error) {
          if (error && error.response) {
            errorHandler(error.response);
          }
        }
      } else if (plan.resource_level_id) {
        try {
          const level = await this.getLevel(plan.resource_level_id.id);
          if (level && level.data && level.data.data) {
            this.selectPlanLevel({
              includes: ["default", "directly_declarable", "resources_count"],
              level_id: level.data.data.id
            });
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
            this.$router.push({
              name: "r_one-click-slots",
              params: {
                one_click: true
              }
            });
          }
        } catch (error) {
          if (error && error.response) {
            errorHandler(error.response);
          }
        }
      }
    },
    wsCallback() {
      this.getOneClickWidgets(false).then(() => {
        this.unresolvedDeclaration = null;
      });
    }
  },
  watch: {
    selectedLevel() {
      if (this.$route.name === "r_one-click") {
        this.$router.push({
          name: "r_one-click-slots"
        });
      }
    }
  },
  beforeUnmount() {
    unsubscribe(this.channel);
    this.channel = null;
  }
};
</script>
