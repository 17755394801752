<template>
  <div class="clebex-widget-item-wrapper persons">
    <div v-if="data && data.length" class="clebex-widget-item">
      <div
        v-for="(contact, i) in data"
        :key="i"
        @click="goToContact(contact.person)"
        class="clebex-widget-contact-wrapper"
        :class="{ 'cursor-default': !contact.person.declarations }"
      >
        <div class="clebex-widget-contact-content">
          <div
            class="clebex-widget-contact-initials"
            v-if="!contact.person.url"
          >
            {{ contact.person.initials }}
          </div>
          <img
            v-else
            :src="contact.person.url"
            class="clebex-widget-contact-photo"
          />
          <div class="clebex-widget-contact-badge-wrapper">
            <icon
              class="clebex-widget-contact-badge"
              v-if="contact.person.declarations"
              icon="#cx-men1-plan"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="clebex-widget-item empty-widget"
      @click="goToContactSettings"
    >
      <icon class="icon" icon="#cx-app1-add" width="40" height="40" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import { getLang, getSubdomain } from "@/services/http-service";
import qs from "qs";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "WidgetsPersons",
  mixins: [mapMixin],
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState("oneClick", ["findPersonDeclarations", "findPersonAssignments"])
  },
  methods: {
    ...mapActions("oneClick", ["setFindPersonList"]),
    goToContactSettings() {
      this.$router.push({
        name: "r_one-click-contacts",
        params: {
          backLinkName: "r_one-click"
        }
      });
    },
    goToContact(person) {
      if (!person.declarations) {
        return;
      }
      const payload = {
        person_id: person.id,
        datetime_from: dayjs()
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        datetime_to: dayjs()
          .utc()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm")
      };
      this.setFindPersonList(payload).then(() => {
        if (
          !(
            this.findPersonDeclarations.length +
            this.findPersonAssignments.length
          )
        ) {
          this.$router.push({
            name: "r_one-click"
          });
        } else if (
          this.findPersonDeclarations.length +
            this.findPersonAssignments.length ===
            1 &&
          ((this.findPersonDeclarations.length &&
            this.findPersonDeclarations[0].level.map_id) ||
            (this.findPersonAssignments.length &&
              this.findPersonAssignments[0].level.map_id))
        ) {
          this.findPersonDeclarations.length
            ? this.toMap(this.findPersonDeclarations[0])
            : this.toMap(this.findPersonAssignments[0], true);
        } else {
          this.$router.push({
            name: "r_one-click-find-person"
          });
        }
      });
    },
    toMap(declaration, isAssignment = false) {
      this.$store.commit("oneClick/setFindPersonUrl", null, {
        root: true
      });
      this.$store.commit("oneClick/setFindPersonMapPath", null, {
        root: true
      });
      let path = null;
      if (declaration.resource.path && declaration.resource.path.length) {
        path = declaration.resource.path.join(", ");
      }
      this.$store.commit("oneClick/setFindPersonMapPath", path, {
        root: true
      });

      const subdomain = getSubdomain();
      let subdomainUrl = "";
      if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;

      const slotsUrl = qs.stringify({
        type: "QUARTER_HOUR",
        slots: {
          from: declaration.datetime_from,
          to: declaration.datetime_to
        }
      });

      const person = `&person=${declaration.user_id}`;

      const declarationId = `&${isAssignment ? "assignment" : "declaration"}=${
        declaration.id
      }`;

      const url = `${process.env.VUE_APP_5DMAPS_URL}/view/${
        declaration.level.map_id
      }?api=${this.getMapApi()}&access_token=${localStorage.getItem(
        "atApp"
      )}&language=${getLang()}&mode=plan${subdomainUrl}&${slotsUrl}${person}${declarationId}`;

      this.$store.commit("oneClick/setFindPersonUrl", url, {
        root: true
      });
      this.$router.push({
        name: "r_one-click-person-map",
        params: {
          backLinkName: "r_one-click"
        }
      });
    }
  }
};
</script>
