<template>
  <div class="clebex-widget-item-wrapper plan map">
    <div v-if="data && data.length" class="clebex-widget-item">
      <div
        v-for="(map, i) in data"
        :key="i"
        class="clebex-widget-plan-wrapper"
        @click="showMap(map)"
      >
        <div class="clebex-widget-plan-text">
          <span class="label">
            <span v-if="map.level" class="text"
              ><span
                v-if="
                  map.level.name &&
                    map.level.full_path &&
                    map.level.full_path.length
                "
                class="highlight"
                >{{ map.level.name
                }}{{ map.level.full_path.length > 1 ? ", " : "" }}</span
              ><span v-if="map.level.full_path && map.level.full_path.length">
                {{
                  map.level.full_path.filter((el, i) => i > 0).join(", ")
                }}</span
              >
            </span>
            <br />
            <span
              v-if="map.types && map.types.length"
              class="second-row-declaration"
              ><span v-for="(type, i) in map.types" :key="type.id"
                >{{ i > 0 ? ", " : ""
                }}{{
                  type.translations.find(el => el.locale === locale)
                    ? type.translations.find(el => el.locale === locale).name
                    : type.name
                }}</span
              ></span
            >
            <br />
            <span
              v-if="map.period && map.period.name"
              class="third-row-declaration"
              >{{ map.period.name }} | {{ getTime(map.datetime_from) }} -
              {{ getTime(map.datetime_to) }}</span
            >
          </span>
        </div>
        <icon icon="#cx-men1-5d-maps" width="20" height="20" />
      </div>
    </div>
    <div
      v-else
      class="clebex-widget-item empty-widget"
      @click="goToMapSettings"
    >
      <icon class="icon" icon="#cx-app1-add" width="40" height="40" />
      <div class="empty-widget-label">
        {{ displayLabelName("one-click", "widget-map", "add-map") }}
      </div>
    </div>
  </div>
</template>

<script>
import { getLang, getSubdomain } from "@/services/http-service";
import { DateTime } from "luxon";
import qs from "qs";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "WidgetsMap",
  mixins: [mapMixin],
  data() {
    return {};
  },
  computed: {
    locale() {
      return getLang();
    }
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    getTime(date) {
      return DateTime.fromISO(date).toFormat("HH:mm");
    },
    showMap(map) {
      this.$store.commit("oneClick/setMapUrl", null, {
        root: true
      });
      const subdomain = getSubdomain();
      let subdomainUrl = "";
      if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;

      const mapUrl = qs.stringify({
        access_token: localStorage.getItem("atApp"),
        language: getLang(),
        mode: "oneclick",
        type: map.type,
        slots: {
          from: map.datetime_from,
          to: map.datetime_to
        },
        types: map.types.map(el => el.id)
      });
      const url = `${process.env.VUE_APP_5DMAPS_URL}/view/${
        map.level.map_id
      }?api=${this.getMapApi()}&${mapUrl}${subdomainUrl}`;

      this.$store.commit("oneClick/setMapUrl", url, {
        root: true
      });
      this.$router.push({
        name: "r_one-click-map-page"
      });
    },
    goToMapSettings() {
      this.$router.push({
        name: "r_one-click-map-add",
        params: {
          backLinkName: "r_one-click"
        }
      });
    }
  }
};
</script>
