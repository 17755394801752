<template>
  <div class="clebex-widget-item-wrapper info">
    <div v-if="data && data.length" class="clebex-widget-item">
      <div
        v-for="(link, i) in data"
        :key="i"
        @click="goToLink(link)"
        class="clebex-widget-info-wrapper"
      >
        <div class="clebex-widget-info-content" style="overflow: hidden;">
          <img
            :ref="`faviconImg${i}`"
            :src="link.icon"
            class="clebex-widget-info-photo"
            :alt="
              link.description
                ? link.description.substring(0, 5).toUpperCase()
                : link.name.substring(0, 5).toUpperCase()
            "
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="clebex-widget-item empty-widget"
      @click="goToInfoSettings"
    >
      <icon class="icon" icon="#cx-app1-add" width="40" height="40" />
    </div>
  </div>
</template>

<script>
export default {
  name: "WidgetsInfo",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    goToInfoSettings() {
      this.$router.push({
        name: "r_one-click-info-add",
        params: {
          backLinkName: "r_one-click"
        }
      });
    },
    goToLink(link) {
      this.$store.commit("oneClick/setInfoUrl", link.url, {
        root: true
      });
      this.$store.commit("oneClick/setInfoName", link.name, {
        root: true
      });
      this.$router.push({
        name: "r_one-click-open-info"
      });
      // }
    }
  }
};
</script>
