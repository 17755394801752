<template>
  <div class="clebex-widget-item-wrapper space-passes">
    <div v-if="data && data.length" class="clebex-widget-item">
      <div
        v-for="(pass, i) in data"
        :key="i"
        @click="goToSpacePass(pass.id)"
        class="clebex-widget-pass-wrapper"
      >
        <div class="clebex-widget-pass-top-row">
          <div class="clebex-widget-pass-resource">
            {{ pass.resource.name }}
          </div>
          <div
            v-if="
              !(
                pass.spacepassable_type === 'RESOURCE_ASSIGNMENT' &&
                !pass.datetime_to
              )
            "
            class="clebex-widget-pass-date"
          >
            <time :datetime="pass.datetime_from">
              {{ displayDate(pass.datetime_from) }}
            </time>
            <time
              v-if="
                pass.spacepassable_type === 'RESOURCE_ASSIGNMENT' &&
                  pass.datetime_to
              "
              :datetime="pass.datetime_to"
            >
              -
              {{ displayDate(pass.datetime_to) }}
            </time>
          </div>
          <div
            v-if="pass.spacepassable_type === 'DECLARATION'"
            class="clebex-widget-pass-timerange"
          >
            <time :datetime="pass.datetime_from">{{
              displayTime(pass.datetime_from)
            }}</time>
            -
            <time :datetime="pass.datetime_to">{{
              displayTime(pass.datetime_to)
            }}</time>
          </div>
        </div>
        <div class="clebex-widget-pass-bottom-row">
          <div
            v-if="pass.declaration_status"
            class="clebex-widget-pass-status"
            :class="{
              active: ['STARTED', 'CHECKED_IN', 'EXTENDED'].includes(
                pass.declaration_status.status
              ),
              warning: ['PRE_CHECK_IN', 'EXPIRING_WARNING'].includes(
                pass.declaration_status.status
              ),
              error: ['EXPIRING_WARNING'].includes(
                pass.declaration_status.status
              )
            }"
          >
            <icon :icon="displayIcon(pass.declaration_status.status)"></icon>
          </div>
          <div
            v-else-if="pass.spacepassable_type === 'RESOURCE_ASSIGNMENT'"
            class="clebex-widget-pass-status active"
          >
            <icon icon="#cx-app1-thumbs-up-80x80"></icon>
          </div>
          <div class="clebex-widget-pass-qr">
            <img
              v-if="pass.qrcode"
              :src="pass.qrcode"
              width="70"
              height="70"
              alt="Qr code placeholder"
            />
            <icon v-else width="70" height="70" icon="#cx-app1-no-qrcode" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/services/helpers";
import { mapGetters, mapState } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "WidgetsSpacePasses",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState("timezone", ["timezones"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"])
  },
  methods: {
    formatDate(date, format) {
      return formatDate(date, format);
    },
    displayDate(date) {
      if (!date) {
        return "";
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return "";
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    displayIcon(status) {
      let result;
      switch (status) {
        case "EXPIRING_WARNING":
          result = "#cx-app1-about-to-end-80x80";
          break;
        case "AUTO_RELEASED":
          result = "#cx-app1-auto-release-80x80";
          break;
        case "PRE_CHECK_IN":
          result = "#cx-app1-check-in-80x80";
          break;
        case "CHECKED_IN":
        case "STARTED":
          result = "#cx-app1-in-use-80x80";
          break;
        default:
          result = "#cx-app1-thumbs-up-80x80";
      }
      return result;
    },
    goToSpacePass(id) {
      this.$store.commit("pass/setBackLinkName", "r_one-click", { root: true });
      this.$router.push({
        name: "r_pass",
        params: {
          pass_id: id
        }
      });
    }
  }
};
</script>
